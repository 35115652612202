import $ from './jquery-3.6.1.min.js'
// 
// user.js
// Use this to write your custom JS
//

window.sendMail2 = function (document) {
	
    // Get the inputs and place into variables
    var sender = document.getElementById("contactemail").value;
    var name = document.getElementById("fullname").value;
    var company = document.getElementById("contactcompany").value;
    var msg = document.getElementById("contactmessage").value;
    var phone = document.getElementById("contactphone").value;
    var subject = "Enquiry by " + name;
    var body = company + "<br>" + msg;
    
    
    $.ajax({
	    type: "POST",
	    url: 'https://contactform.tcscontrols.com.my/sendemail/send.php',
	    dataType: "json",
	    data: {'sender': sender, 'name': name, 'company': company, 'msg': msg, 'phone': phone, 'subject': subject, 'body': body},
	    success: function(result) {
			if( result.status == 1){
				console.log("Sending...");
			}else{
				console.log("Error 1");
			}
	    },
	    error: function() {
			console.log("Error 2");
	    },
	});
	
	setTimeout(function(){
		alert("Your message successfully sent.");
	}, 200)
   
}